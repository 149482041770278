.uc {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;


  svg rect.finger:nth-child(1),
  svg rect.finger:nth-child(4),
  svg rect.finger:nth-child(7) {
    animation: animate-finger .7s forwards infinite;
  }

  svg rect.finger:nth-child(2),
  svg rect.finger:nth-child(5) {
    animation: animate-finger .6s forwards infinite;
  }

  svg rect.finger:nth-child(3),
  svg rect.finger:nth-child(6),
  svg rect.finger:nth-child(8) {
    animation: animate-finger .5s forwards infinite;
  }

  @keyframes animate-finger {
    95% {
      transform: translateY(4px)
    }
  }

  svg g#key-three {
    -webkit-animation: animate-third 3s ease-out 1.5s infinite;
    -moz-animation: animate-third 3s ease-out 1.5s infinite;
    -ms-transition: animate-third 3s ease-out 1.5s infinite;
    transition: animate-third 3s ease-out 1.5s infinite;
    -o-animation: animate-third 3s ease-out 1.5s infinite;
    animation: animate-third 3s ease-out 1.5s infinite;
    opacity: 0;
  }

  svg g#key-two {
    -webkit-animation: animate-second 3s ease-out 1.5s infinite;
    -moz-animation: animate-second 3s ease-out 1.5s infinite;
    -ms-transition: animate-second 3s ease-out 1.5s infinite;
    transition: animate-second 3s ease-out 1.5s infinite;
    -o-animation: animate-second 3s ease-out 1.5s infinite;
    animation: animate-second 3s ease-out 1.5s infinite;
    opacity: 0;
  }

  svg g#key-one {
    -webkit-animation: animate-first 3s ease-out 1.5s infinite;
    -moz-animation: animate-first 3s ease-out 1.5s infinite;
    -ms-transition: animate-first 3s ease-out 1.5s infinite;
    transition: animate-first 3s ease-out 1.5s infinite;
    -o-animation: animate-first 3s ease-out 1.5s infinite;
    animation: animate-first 3s ease-out 1.5s infinite;
    opacity: 0;
  }

  /*---------------------- Hand Animation ---------------------- */
  @keyframes animate-hand {
    from {
      opacity: 0;
      transform: rotate(360deg) translate(100px, -50px) rotate(0deg);
    }

    to {
      opacity: 1;
      transform: rotate(180deg) translate(60px, -50px) rotate(180deg);
    }
  }

  @-o-keyframes animate-hand {
    from {
      opacity: 0;
      -o-transform: rotate(360deg) translate(100px, -50px) rotate(0deg);
    }

    to {
      opacity: 1;
      -o-transform: rotate(180deg) translate(60px, -50px) rotate(180deg);
    }
  }

  @-ms-keyframes animate-hand {
    from {
      opacity: 0;
      -ms-transform: rotate(360deg) translate(100px, -50px) rotate(0deg);
    }

    to {
      opacity: 1;
      -ms-transform: rotate(180deg) translate(60px, -50px) rotate(180deg);
    }
  }

  @-moz-keyframes animate-hand {
    from {
      opacity: 0;
      -moz-transform: rotate(360deg) translate(100px, -50px) rotate(0deg);
    }

    to {
      opacity: 1;
      -moz-transform: rotate(180deg) translate(60px, -50px) rotate(180deg);
    }
  }

  @-webkit-keyframes animate-hand {
    from {
      opacity: 0;
      -webkit-transform: rotate(360deg) translate(100px, -50px) rotate(0deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotate(180deg) translate(60px, -50px) rotate(180deg);
    }
  }


  /*---------------------- First Keyboard Key Animation ---------------------- */
  @keyframes animate-first {
    0% {
      opacity: 0;
      transform: rotate(180deg) translateX(-130px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: rotate(0deg) translate(-100px, -180px) rotate(360deg);
    }
  }

  @-o-keyframes animate-first {
    0% {
      opacity: 0;
      -o-transform: rotate(180deg) translateX(-130px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -o-transform: rotate(0deg) translate(-100px, -180px) rotate(360deg);
    }
  }

  @-ms-keyframes animate-first {
    0% {
      opacity: 0;
      -ms-transform: rotate(180deg) translateX(-130px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -ms-transform: rotate(0deg) translate(-100px, -180px) rotate(360deg);
    }
  }

  @-moz-keyframes animate-first {
    0% {
      opacity: 0;
      -moz-transform: rotate(180deg) translateX(-130px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -moz-transform: rotate(0deg) translate(-100px, -180px) rotate(360deg);
    }
  }

  @-webkit-keyframes animate-first {
    0% {
      opacity: 0;
      -webkit-transform: rotate(180deg) translateX(-130px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -webkit-transform: rotate(0deg) translate(-100px, -180px) rotate(360deg);
    }
  }


  /*---------------------- Second Keyboard Key Animation ---------------------- */
  @keyframes animate-second {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateY(-220px);
    }
  }

  @-o-keyframes animate-second {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -o-transform: translateY(-220px);
    }
  }

  @-ms-keyframes animate-second {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -ms-transform: translateY(-220px);
    }
  }

  @-moz-keyframes animate-second {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -moz-transform: translateY(-220px);
    }
  }

  @-webkit-keyframes animate-second {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -webkit-transform: translateY(-220px);
    }
  }


  /*---------------------- Third Keyboard Key Animation ---------------------- */
  @keyframes animate-third {
    0% {
      opacity: 0;
      transform: rotate(180deg) translateX(120px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: rotate(360deg) translate(100px, -180px) rotate(0deg);
    }
  }

  @-o-keyframes animate-third {
    0% {
      opacity: 0;
      -o-transform: rotate(180deg) translateX(120px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -o-transform: rotate(360deg) translate(100px, -180px) rotate(0deg);
    }
  }

  @-ms-keyframes animate-third {
    0% {
      opacity: 0;
      -ms-transform: rotate(180deg) translateX(120px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -ms-transform: rotate(360deg) translate(100px, -180px) rotate(0deg);
    }
  }

  @-moz-keyframes animate-third {
    0% {
      opacity: 0;
      -moz-transform: rotate(180deg) translateX(120px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -moz-transform: rotate(360deg) translate(100px, -180px) rotate(0deg);
    }
  }

  @-webkit-keyframes animate-third {
    0% {
      opacity: 0;
      -webkit-transform: rotate(180deg) translateX(120px) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      -webkit-transform: rotate(360deg) translate(100px, -180px) rotate(0deg);
    }
  }
}

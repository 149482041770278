.braille-machine {
  background-color: #707070;
  height: 160px;
  width: 400px;
  border-radius: 50px;
  padding-top: 80px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  .braille-cell {
    background-color: #a0a0a0;
    height: 100px;
    width: 360px;
    border-radius: 35px;

    padding-top: 16px;
    padding-left: 18px;

    .dot-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      background-color: #fff;

      width: 324px;
      height: 74px;
      border-radius: 8px;

      .dot {
        width: 48px;
        height: 64px;

        background-color: #000;
        border-radius: 8px;
 
        color: #fff;

        &.raised {
          background-color: #707070;
        }
      }
    }


  }
}

.braille-output {
  margin-bottom: 40px;
}

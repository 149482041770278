.main {
  width: 100%;
  height: 100%;

  &.is-small-padding {
    padding: 1rem 1rem;
  }
}

#toolbar_container {
  border: none;
  padding-right: 0;
}

.reader {
  border: 1px hsl(0, 0%, 86%) solid;
  border-radius: 6px;
  height: 100%;

  white-space: normal;
  overflow-wrap: break-word;

  font-family: Helvetica Neue;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  word-break: normal;
  word-wrap: break-word;
  padding: 16px;
  margin: 0;

  overflow-y: auto;
}

.is-layout-container  {
  min-height: 400px;
  height: 100%;
}

.is-editor-wrapper {
  padding-left: 0;
  padding-right: 6px;
  flex: none;
  width: calc(50% - 96px);
}

.is-reader-wrapper {
  padding-left: 6px;
  flex: none;
  width: calc(50%);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-button:single-button:hover,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-track:hover {
  cursor: default;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgb(240, 240, 240);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(133, 133, 133);
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(133, 133, 133);
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: rgb(240, 240, 240);
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='60,5 52,0 48,0 40,5 0,45 0,48 5,50 95,50 100,48 100,45'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='60,5 52,0 48,0 40,5 0,45 0,48 5,50 95,50 100,48 100,45'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='60,5 52,0 48,0 40,5 0,45 0,48 5,50 95,50 100,48 100,45'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}
